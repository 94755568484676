.form-type-explanation {
    margin-top: -20px;
}

.form-type-explanation button {
    margin-top: 2.4rem;
}

.form-type-explanation h2 {
    margin-bottom: 1.25rem;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: #006699;
}

.form-type-explanation h4 {
    margin-bottom: 0.5rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #006699;
}

.form-type-explanation p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #333333;
}