.button {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem 1.5rem;
  white-space: normal;
  margin-top: 2.5rem;
  outline: 0;
  border-radius: .3em;
  transition: .3s background-color, .3s border-color;
  border: 1px solid transparent;
  cursor: pointer;
  font-family: 'Titillium Web', sans-serif;
  &-group {
    & > .button-container:nth-of-type(odd) {
      margin-right: 8px;
    }
    &.no-margin {
      .button {
        margin: 0;
      }
    }
    &-right {
      display: flex;
      justify-content: flex-end;
    }
    .button {
      border-radius: 0;
      &:first-of-type {
        border-top-left-radius: .3em;
        border-bottom-left-radius: .3em;
      }
      &:last-of-type {
        border-top-right-radius: .3em;
        border-bottom-right-radius: .3em;
      }
    }
  }
  &.primary {
    background-color: $primary-colour;
    border-color: darken($primary-colour, 5%);
    &:hover {
      background-color: darken($primary-colour, 5%);
      border-color: darken($primary-colour, 10%);
    }
    &:active {
      background-color: darken($primary-colour, 10%);
    }
  }
  &.default {
    background-color: $white;
    border-color: darken($light-grey-blue, 5%);
    color: $primary-colour;
    &:hover {
      background-color: $light-grey-blue;
      border-color: darken($light-grey-blue, 10%);
    }
    &:active {
      background-color: $light-grey-blue, 5%;
    }
  }
  &.success {
    background-color: $success-colour;
    border-color: darken($success-colour, 5%);
    &:hover {
      background-color: darken($success-colour, 5%);
      border-color: darken($success-colour, 10%);
    }
    &:active {
      background-color: darken($success-colour, 10%);
    }
  }
  &.secondary {
    background-color: $secondary-colour;
    border-color: darken($secondary-colour, 5%);
    &:hover {
       background-color: darken($secondary-colour, 5%);
       border-color: darken($secondary-colour, 10%);
     }
     &:active {
       background-color: darken($secondary-colour, 10%);
     }
   }
}