.section- {
  &content {
    display: flex;
    padding: 2rem 0;
    flex-direction: column;
    box-shadow: inset 0 200px 139px -132px rgba(255, 255, 255, 1), inset 0px -200px 139px -132px rgba(255, 255, 255, 1);
  }
  &submit {
    &-container {
      display: flex;
      display: -ms-flexbox;
      align-items: flex-end;
    }
    &-hint {
      padding: 0 0 1rem 1rem;
      color: $secondary-colour;
    }
  }
  &input-group {
    flex: 0 0 auto;
    transition: 0.5s opacity cubic-bezier(0.215, 0.61, 0.355, 1);
    padding-bottom: 2.5rem;
    position: relative;
    &.no-spacing {
      padding-bottom: 0 !important;
    }
    .select-container,
    .text-input-container:not(.text-area) {
      max-width: 45rem;
    }
    &.fileupload-border {
      &.is-active.read-only .file-upload-title {
        opacity: 0.55;
      }
      .section-submit-container {
        display: flex;
        justify-content: center;
        &:not(.file-upload-footer) {
          border-left: 2px solid $light-grey;
          border-right: 2px solid $light-grey;
        }
      }
      .file-upload-footer {
        display: flex;
        justify-content: center;
        padding: 0 2rem 2rem;
        border-bottom-left-radius: 0.75rem;
        border-bottom-right-radius: 0.75rem;
        border: 2px solid $light-grey;
        border-top: 0;
      }
    }
    &.editable {
      opacity: 0.25;
    }
    &.not-active:not(.editable)::after {
      content: '';
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      display: block;
      position: absolute;
      z-index: 5;
      background-color: rgba(255, 255, 255, 0.75);
    }
    .button {
      display: inline-block;
    }
  }
  &container {
    margin: 2.5rem 0 0 0;
    padding: 0 0 1rem 5rem;
    position: relative;

    &.inactive {
      .section-title {
        color: $primary-colour-light;
      }

      .section-index {
        background-color: $primary-colour-light !important;
      }
    }

    &.is-collapsed {
      padding: 0 0 0 3rem;

      .section-wrapper {
        height: 0 !important;
      }

      &::after {
        border-color: transparent !important;
        transition: 0.5s ease border-left-color !important;
      }
    }

    &::after {
      content: '';
      border-left: 1px $secondary-colour solid;
      position: absolute;
      left: 2rem;
      top: 6.5rem;
      height: calc(100% - 8rem);
      transition: 0.5s ease border-left-color 0.1s;
      @include respondOnly(xxs, xs) {
        display: none;
      }
      @include respondOnly(sm) {
        left: 1.6rem;
      }
    }

    @include respondOnly(xxs, xs) {
      margin: 2rem 0 0;
      padding: 0;
    }

    @include respondOnly(sm) {
      padding-left: 4.7rem;
    }
  }

  &wrapper {
    margin: 0;
  }

  &content-wrapper {
    transition: 1s top cubic-bezier(0.215, 0.61, 0.355, 1);
    position: relative;
    &:nth-child(1 of .section-input-group) {
      padding: 0 2rem 2rem;
      border-bottom-left-radius: 0.75rem;
      border-bottom-right-radius: 0.75rem;
      border: 2px solid $light-grey;
      border-top: 0;
    }
  }

  &title {
    font-size: 1.8rem;
    font-weight: 300;
    color: $primary-colour;
    font-family: 'Rubik', sans-serif;
    display: inline-block;
    cursor: pointer;
    position: relative;
    top: 0.4rem;

    &.section-title-active {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      position: relative;
      top: 3px;
      left: 0;
      height: 1.5rem;
      width: 2.5rem;
      margin-left: 1rem;
      transition: transform 0.3s;
    }

    &.inactive-title {
      display: none;
    }
  }

  &subtitle {
    font-size: 1.4rem;
    padding: 1rem 0 0;
    font-weight: 300;
    line-height: 2.2rem;
  }

  &index {
    position: absolute;
    top: 1.5rem;
    left: 2rem;
    height: 3rem;
    width: 3rem;
    transform: translate(-50%, -50%);
    background: $secondary-colour;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: bold;
    line-height: 1;
    @include respondOnly(xxs, xs) {
      left: -4.4rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      transform: translateY(-50%);
    }
    @include respondOnly(sm) {
      left: 1.7rem;
    }
  }
}
