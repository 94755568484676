.cost-coverage-label {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  background-color: #f9fcff;
}

.cost-coverage-label__icon {
  margin: 4px 0;
  padding: 0;
  width: 28px;
  height: 28px;
}

.cost-coverage-label__text {
  margin: 0;
  padding: 0 0 0 12px;
  flex-basis: calc(100% - 28px);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #006699;
  & span {
    color: #1eb05c;
    font-weight: 600;
  }
}