.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  &-container {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
  }
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #247aa8;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #247aa8 transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}