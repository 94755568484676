html {
  font-size: 10px;
}

body {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.3;
}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Titillium Web";
  font-weight: 400;
  color: $primary-colour;
  margin-bottom: .5rem;
  margin-top: 0;
}

h1, .h1 {
  font-size: 2.8rem;
}
h2, .h2 {
  font-size: 2.4rem;
}
h3, .h3 {
  font-size: 2rem;
}
h4, .h4 {
  font-size: 1.6rem;
}
h5, .h5 {
  font-size: 1.2rem;
}
h6, .h6 {
  font-size: .8rem;
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {

}