.upload-files {
  &__section {
    padding-top: 2.4rem;
  }
  &__info {
    margin-bottom: 2.4rem;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2.2rem;
    color: #333333;
    u {
      text-decoration: underline;
      font-weight: bolder;
    }
  }
  &__dropzone {
    padding: 2.4rem 2.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #E3EAEF;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      border-color: #66a3c2;
    }
  }
  &__title {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #006699;
    text-align: center;
    strong {
      font-weight: 600;
    }
  }
  &__icon {
    margin-top: 2.4rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    color: rgba(162, 186, 201, 0.3);
    height: 3rem;
    width: 3rem;
  }
  &__invite {
    margin-top: 0.8rem;
    border: none;
    background-color: transparent;
    display: flex;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #ff8d02;
    text-decoration: underline;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }
  &__size {
    margin-top: 2.4rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #CCE0EB;
    text-align: center;
  }
  &__list {
    width: 100%;
  }
  &__list-item {
    background: #F8FAFB;
    padding: 1rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #006699;
    &:not(:only-of-type) {
      margin-top: 1rem;
    }
    &:first-of-type {
      margin-top: 2.4rem;
    }
  }
  &__check-icon {
    margin-right: 0.8rem;
    width: 2rem;
    color:#DEE7EC
  }
  &__list-item-text {
    flex-grow: 1;
    max-width: calc(100% - 5.6rem);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__delete-icon {
    margin-left: 0.8rem;
    border: none;
    background-color: transparent;
    color: #DEE7EC;
    & svg {
      width: 2rem;
    }
    &:hover {
      cursor: pointer;
      color: #006699;
    }
  }
  &__topic {
    width: 100%;
    margin-top: 2.4rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    border-top: 2px solid #E3EAEF;
    border-bottom: 2px solid #E3EAEF;
  }
  &__topic-title {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #333333;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    p {
      flex-basis: calc(100% - 2rem);
    }
    svg {
      width: 1.5rem;
      margin-left: 0.5rem;
      margin-top: 0.5rem;
    }
    button {
      border: none;
      background-color: transparent;
      padding: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &__select {
    padding-top: 0.8rem;
    width: 100%;
    .select-container {
      max-width: 100% !important;
    }
  }
  &__button {
    margin-top: 2.4rem;
  }
  &__add-more-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  &__add-more {
    padding: 0;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #FF8D02;
    & > svg {
      margin-right: 8px;
      width: 12px;
      height: 12px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__added-files {
    margin-top: 24px;
  }
  &__added-files-title {
    margin-bottom: 8px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #006699;
  }
  &__added-files-item {
    margin: 8px 0;
    min-height: 40px;
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    background-color: #EEF9F0;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #006699;
    & > svg {
      width: 14px;
    }
  }
}