.text-input-,
.number-input- {
  &container {
    position: relative;
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;

    &.is-valid {
      .text-input,
      .number-input {
        border-color: $success-colour;
      }
    }

    &.is-not-valid {
      .text-input,
      .number-input {
        border-color: $error-colour;
      }
    }
  }

  &error {
    background-color: $error-colour;
    color: $white;
    font-size: 11px;
    top: 0;
    position: absolute;
    padding: 1rem;
    right: 0;
    transform: translateY(-45%);

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      right: 10px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $error-colour transparent transparent transparent;
    }
  }

  &label {
    display: block;
    font-weight: bold;
    width: 100%;
    margin-bottom: 12px;
  }
}

.text-input,
.number-input {
  outline: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
  line-height: 1.42857143;
  background-color: #fff;
  background-image: none;
  font-weight: 300;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 2px solid $primary-colour-light;
  border-radius: 2px;
  resize: vertical;

  &:focus {
    border-color: $secondary-colour;
  }
}

.number-input {
  width: 6rem;
  flex: 0 0 auto;
  text-align: left;

  &-error {
    right: unset;
    left: 0;

    &::after {
      right: unset;
      left: 10px;
    }
  }

  &-wrapper {
    position: relative;
    display: flex;
  }

  &-control-desktop {
    @include respondOnly(xxs, xs, sm) {
      display: none;
    }
    div {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      width: 1rem;
      height: 1rem;
      color: mix(white, $primary-colour, 70%);
      transition: color 0.3s;

      &:hover {
        cursor: pointer;
        color: $primary-colour;
      }

      &:last-of-type {
        top: unset;
        bottom: 1.25rem;
      }

      svg {
        width: 1rem;
        height: auto;
      }
    }
  }

  &-control-mobile {
    flex: 1 1 auto;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    @include respondFrom(md) {
      display: none;
    }
    div {
      width: 100%;
      height: auto;
      padding-left: 1rem;
      color: mix(white, $primary-colour, 70%);
      transition: color 0.3s;
      align-items: center;
      display: flex;
      display: -ms-flexbox;

      &:hover {
        cursor: pointer;
        color: $primary-colour;
      }

      &:last-of-type {
        padding-top: 0.5rem;
      }

      svg {
        width: 1.75rem;
        height: auto;
      }
    }
  }
}
