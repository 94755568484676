.dropzone- {
  &container {
    padding: 2.5rem;
    border: 2px dashed $primary-colour-light;
    border-radius: 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: mix(white, $primary-colour, 40%);
    transition: 0.3s border-color;
    &:hover {
      border-color: mix(white, $primary-colour, 40%);
      cursor: pointer;
      .dropzone-open {
        text-decoration: none;
        color: darken($secondary-colour, 10%);
      }
    }
    .dropzone-criteria {
      width: 100%;
      flex: 1 0 100%;
      margin-top: 2rem;
      text-align: center;
      color: mix(white, $primary-colour, 80%);
      font-size: 1.2rem;
    }
    .dropzone-hint {
      text-align: center;
      color: $primary-colour;
    }
    .dropzone-accepted-files-container {
      width: 100%;
      flex: 1 0 100%;
    }
    .dropzone-accepted-files-item {
      &:not(:first-of-type) {
        margin-top: 1rem;
      }
      &:first-of-type {
        margin-top: 2rem;
      }
      padding: 1rem;
      background-color: $lighter-grey-blue;
      color: $primary-colour;
      border-radius: 2px;
      position: relative;
      display: flex;
      display: -ms-flexbox;
      align-items: center;
      &-label {
        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        flex: 1 1 auto;
        display: inline-grid;
      }
      &-file {
        height: 2rem;
        margin-right: 0.8rem;
        width: 2rem;
        color: $primary-colour-light;
      }
      &-delete {
        position: relative;
        height: 2rem;
        width: 2rem;
        color: $primary-colour-light;
        transition: 0.2s transform, 0.2s color;
        &:hover {
          color: $primary-colour;
          transform: scale(1.1);
        }
        svg {
          width: 2rem;
          height: 2rem;
          cursor: pointer;
        }
      }
    }
    &.is-dragging {
      color: $primary-colour;
    }
    > svg {
      color: $primary-colour-light;
      height: 3rem;
      width: 3rem;
      margin-top: 2rem;
    }
  }
  &open {
    color: $secondary-colour;
    text-decoration: underline;
  }
}
