body {
	color: #333;
	background-color: #f4f4f4;
	font-family: Arial, sans-serif;
}

.bg {
	position: absolute;
	width: 100%;
	left: 0;
	top: 166px;
	height: 110px;
	background-color: #247aa8;
	z-index: -1;
}

a,
a:hover {
	color: #247aa8;
}

.main {
	max-width: 850px;
	width: 100%;
	overflow: hidden;
	margin: 40px auto 0;
	-webkit-box-shadow: 0 0 8px 0 rgba(97, 97, 97, 0.75);
	-moz-box-shadow: 0 0 8px 0 rgba(97, 97, 97, 0.75);
	box-shadow: 0 0 8px 0 rgba(97, 97, 97, 0.75);
	background-color: white;
}

.wrapper {
	background-color: white;
	border-top: 2px solid #247aa8;
	border-bottom: 1px solid #bcbcbc;
	display: block;
	overflow: hidden;
}

header {
	/*height: 124px;*/
}

.header-content {
	margin-top: 20px;
	margin-bottom: 20px;
	overflow: hidden;
}

.attachment-header {
	margin-left: 30px;
	margin-right: 30px;
}

.logo-link {
	margin-bottom: 9px;
	display: block;
	float: left;
}

.logo-line {
	display: block;
	height: 2px;
	background-color: #efefef;
	margin-left: 30px;
	margin-right: 30px;
}

.info-line {
	display: block;
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 10px;
	font-size: 12px;
}

header h1 {
	margin-left: 30px;
	margin-right: 30px;
	font-size: 15px;
	margin-bottom: 25px;
}

.logo-line-inside {
	display: block;
	width: 73px;
	background-color: #247aa8;
	height: 2px;
}

h1 .span-blue {
	color: #247aa8;
}

h1 {
	font-size: 15px;
	font-weight: bold;
	margin-top: 11px;
}

img.info-link {
	margin-bottom: 15px;
}

.main .first-col {
	width: 100%;
	float: left;
	display: block;
	clear: both;
	background-color: white;
	padding-bottom: 36px;
}

.main .second-col {
	width: 33%;
	box-sizing: border-box;
	float: left;
	display: block;
}

.step-holder {
	height: 109px;
	width: 100%;
	background-color: #f7f7f7;
	display: block;
	border-bottom: 1px solid #bcbcbc;
	overflow: hidden;
	position: relative;
}

.second-col {
	width: 100%;
	background-color: #f7f7f7;
	border-left: 1px solid #bcbcbc;
}

.second-col h2 {
	font-weight: bold;
	font-size: 27px;
	margin-top: 44px;
}

.first-col h2 {
	font-size: 27px;
	font-weight: bold;
	margin-top: 30px;
	margin-bottom: 25px;
}

.main form {
	width: 100%;
	display: block;
}

.main form label {
	font-size: 12px;
	font-weight: normal;
}

.btn {
	border: 0;
	font-size: 13px;
	font-weight: bold;
	padding: 15px;
	white-space: normal;
}
.btn.btn-wide {
	padding-left: 40px;
	padding-right: 40px;
}
.btn.btn-primary {
	background-color: #247aa8;
}
button,
.btn.btn-grey {
	background-color: #9a9a9a;
	color: #fff;
}

.no-list,
.no-list li {
	list-style: none;
	padding-left: 0;
	margin-left: 0;
}

.help-group {
	display: inline-block;
	width: 100%;
}

.has-error {
	border: 1px solid #cc2c28 !important;
}

.checkboxes .col-xs-12 {
	padding-top: 5px;
	padding-bottom: 5px;
}

.col-xs-12.has-error,
.agb-box.has-error {
	background-position: right 5px top 5px;
	border: 0 !important;
	background-color: #ffe5e5;
}

.help-error {
	color: #cc2c28;
}

.payment .has-error:not(select) {
	background-image: none;
}

.payment label {
	cursor: pointer;
}

.popup-error {
	background-color: #cc2c28;
	color: white;
	font-size: 12px;
	padding: 10px;
	position: absolute;
	text-align: center;
	bottom: 55px;
	display: none;
}

.popup-error img {
	bottom: -5px;
	position: absolute;
}

form .btn.btn-primary.back-btn {
	background-color: #e5e5e5;
	border: 0;
	font-size: 13px;
	font-weight: bold;
	padding: 10px 30px;
	color: #777777;
}

.steps {
	margin-top: 19px;
	overflow: hidden;
}

.steps .step-count {
	width: 100%;
	height: 36px;
	background-color: #e5e5e5;
	border-radius: 5px;
	display: block;
	overflow: hidden;
}

.steps .step-count span {
	float: left;
	display: block;
	width: 28px;
	height: 24px;
	background-color: white;
	color: #999;
	border-radius: 5px;
	margin: 6px 10px;
	line-height: 24px;
	font-weight: bold;
	text-align: center;
}

.steps .step-count h3 {
	color: #858585;
	font-size: 11px;
	margin: 12px 0 0;
	float: left;
}

.steps .step-count.active {
	background-color: #247aa8;
}

.steps .step-count.active span {
	color: #247aa8;
}

.steps .step-count.active h3 {
	color: white;
}

.step-progress-bar {
	width: 100%;
	position: absolute;
	height: 2px;
	background-color: #e5e5e5;
}

.step-progress {
	background-color: #247aa8;
	height: 2px;
	display: block;
}

.step-graph {
	clear: both;
	display: block;
	margin-top: 17px;
	overflow: hidden;
}

.step-graph .col-lg-2 {
	color: #333;
	font-size: 11px;
}

.graph-container {
	background-color: #e5e5e5;
	height: 20px;
	display: block;
	border-radius: 4px;
}

.graph-container .text-center {
	font-size: 11px;
	font-weight: bold;
	color: #656464;
	line-height: 20px;
}

.graph {
	height: 12px;
	border-radius: 4px;
}

.graph-content {
	background-color: white;
	width: 100%;
	height: 12px;
	border-radius: 3px;
	margin-top: 4px;
}

.graph-active {
	background-color: #247aa8;
	width: 33%;
	height: 12px;
	border-radius: 3px;
}

.cart {
	clear: both;
	margin-bottom: 15px;
}

.cart-hint h4 {
	font-size: 11px;
	color: #777;
	font-weight: bold;
}

.cart-hint p {
	font-size: 11px;
	color: #777;
}

.cart-table.skip {
	text-align: center;
}

.cart-table {
	border: 1px solid #bcbcbc;
	border-radius: 5px;
	padding: 15px;
}

.cart-table table {
	font-size: 11px;
	width: 100%;
	color: #777;
}

.cart-table table td {
	padding: 2px 0;
}

.cart-table table th {
	font-size: 12px;
	font-weight: bold;
	color: #333;
	padding-bottom: 5px;
}

.vat td {
	padding-bottom: 16px !important;
}

.total {
	font-weight: bold;
	color: #247aa8;
}

.total td:last-child {
	color: #247aa8;
	font-size: 15px;
}

.step-3 h3 {
	font-size: 22px;
	color: #333;
	font-weight: bold;
	margin-bottom: 22px;
}

.payment {
	margin-bottom: 25px;
	overflow: hidden;
}

.payment > label {
	height: 52px;
	border-radius: 4px;
	border: 1px solid #bcbcbc;
	float: left;
	margin-right: 10px;
	padding: 11px;
}

.payment > label:last-child {
	margin-right: 0;
}

.payment label img {
	margin-left: 5px;
}

.payment label.ec {
	width: 101px;
}

.payment label.sf {
	width: 101px;
}

.payment label.cc {
	width: 155px;
}

.payment label.pp {
	width: 101px;
}

.agb-box,
.agb2-box {
	display: block;
	overflow: hidden;
}

.agb-box input,
.agb2-box input {
	float: left;
}

.agb-box label,
.agb2-box label {
	margin-left: 37px;
	margin-top: 1px;
	padding-right: 25px;
	display: block;
}

.signature-container {
	margin-top: 20px;
}

.korrektur .signature-container {
	max-width: 500px;
}

.signature-head {
	font-weight: bold;
	font-size: 11px;
	color: #444;
	margin-bottom: 5px;
}

.signature-hint {
	font-size: 11px;
	color: #777;
}

.signature-canvas-container {
	width: 100%;
	height: 120px;
	border: 1px solid #bcbcbc;
	border-radius: 5px;
	display: block;
	overflow: hidden;
	position: relative;
}

.signature-reset {
	font-size: 11px;
	text-decoration: none !important;
	color: #247aa8;
	margin-bottom: 30px;
	margin-top: 10px;
}

.signature-reset-poa {
	color: #ffffff;
}

.help-tick {
	position: absolute;
	left: -11px;
}

.second-col {
	position: relative;
}

.help-container {
	position: absolute;
	left: 0;
	padding: 15px;
	width: 100%;
}

.help-container p {
	font-size: 13px;
	color: #666;
	margin-bottom: 30px;
}

.remodal {
	text-align: left;
}

.remodal p {
	text-align: justify;
}

#signature-pad canvas {
	width: 100%;
	height: 120px;
}

.sigpad-x {
	font-size: 42px;
	font-weight: normal;
	color: #555;
	line-height: 40px;
}

.sigpad-name {
	color: #777;
	font-size: 11px;
	border-top: 1px solid #ddd;
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
}

.m-signature-pad--label {
	width: 298px;
	position: absolute;
	left: 5%;
	bottom: 10px;
}

input[type='checkbox'] {
	outline: 2px solid white;
	margin-left: 2px;
}

input[type='checkbox'].has-error {
	outline: 2px solid #cc2c28;
}

input[type='radio'] {
	background: none;
}

.date-field {
	overflow: hidden;
}

.date-field select:first-child {
	margin-right: 3%;
}

.date-field select:nth-child(2) {
	margin-right: 30px;
}

.date-field select {
	width: 31% !important;
	float: left;
	font-size: 12px;
}

.cc-exp .form-control {
	width: 45% !important;
	float: left;
}

.cc-exp .form-control.margin {
	margin-right: 10%;
}

.payment-group {
	margin-bottom: 20px;
}

.danke .step-holder a {
	cursor: default;
	pointer-events: none;
}

.error-box {
	background-color: #ffcccc;
	border-bottom: 1px solid red;
	padding-top: 10px;
}

.payment-info {
	background-color: #d6eef8;
	border: 1px solid #c1ebf5;
	border-radius: 4px;
	color: #0177a9;
	font-size: 12px;
	padding: 10px;
}

.tel {
	float: right;
	font-size: 16px;
	color: #ccc;
	font-weight: bold;
}

.tel span {
	font-size: 13px;
	margin-right: 5px;
}

h4 {
	font-size: 14px;
	font-weight: bold;
}

.form-group {
	/* overflow: hidden; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
	-moz-appearance: textfield;
}

.icheckbox_minimal-aero,
.icheckbox_minimal-blue,
.icheckbox_polaris {
	float: left;
	margin-top: -5px;
}

.cookieAdvice {
	text-align: center;
	background-color: #dedede;
	font-size: 12px;
}

.cookieAnchor {
	color: #2b2e38;
}

.btn-korrektur {
	margin-bottom: 20px;
}

.call .number {
	background-position: 0 5px;
	padding-left: 45px;
	font-size: 28px;
	line-height: 36px;
	font-weight: 700;
	display: block;
}

hr {
	margin: 20px 0 0 0;
}

.help-group hr {
	margin: 0 0 10px 0;
}

.transmit-later {
	font-size: 12px;
}

.transmit-later p {
	margin-top: 10px;
}
.transmit-later td {
	padding: 0 0 0 10px;
}

#remunerationForm .checkboxes {
	margin-top: 20px;
}
#remunerationForm .graph-active {
	width: 50%;
}

.subpage .step-holder {
	height: 74px;
}

.subpage header h1,
.subpage header h2 {
	margin-left: 30px;
	margin-right: 30px;
	font-size: 25px;
	margin-bottom: 5px;
}

.subpage header h2 {
	margin-top: 0;
	font-size: 20px;
	color: #247aa8;
	margin-bottom: 25px;
}

.subpage .step {
	padding-top: 10px;
}

h4 {
	font-size: 21px;
}

.preview-button {
	border: 1px solid #247aa8;
	color: #247aa8;
	background-color: #fff;
	padding: 9px;
	font-weight: normal;
	border-radius: 5px;
	text-align: center;
}

a.uncollapse-link {
	color: #333;
}

a.uncollapse-link:active,
a.uncollapse-link:focus,
a.uncollapse-link:hover {
	text-decoration: none;
}

a.uncollapse-link .glyphicon {
	font-size: 14px;
}

input[type='file'] {
	height: auto;
}

div#remunerationReceivedBox {
	margin-top: 20px;
}

.main form label[for='poa_agreement_coverMyExpensesAgreement'] {
	color: #3c763d;
	font-weight: bold;
}

.thankyoupage {
	padding: 20px;
}

.thankyoupage div {
	margin-top: 20px;
}

.authority-rec-desc ul li {
	padding-bottom: 5px;
}

.fileupload-container {
	border: 1px solid #cacaca;
	border-radius: 4px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.start-all {
	margin-top: 15px;
}

#uploadFilesModal,
#uploadFilesUploadingModal,
#uploadFilesProcessingModal {
	top: 30%;
}

#loadingSignatureModal {
	top: 45%;
}

#loadingSignatureModal img {
	width: 40px;
}

#loadingSignatureModal .modal-body {
	text-align: center;
}

#loadingSignatureModal h4 {
	font-size: 18px;
	margin-bottom: 15px;
}

@media (min-width: 768px) {
	#loadingSignatureModal .modal-dialog {
		width: 400px;
	}
}

.progress.no-margin {
	margin-bottom: 0;
}

.form-control-autocomplete {
	display: block;
	width: 100%;
}

.select2-choice {
	height: 34px !important;
}

.select2-choice span {
	padding-top: 3px;
}

.select2-container.has-error {
	border-radius: 4px !important;
}

.visible-ie {
	display: none;
}
.collapse-content {
	width: 100%;
	padding-top: 1px;
	padding-bottom: 1px;
}
.content-padding {
	padding-left: 15px;
	padding-right: 15px;
}

.attachment-opening-time {
	color: #adadad;
	font-size: 12px;
	padding-left: 24px;
	padding-top: 5px;
}

.attachment-opening-time h5 {
	font-weight: bold;
	font-size: 12px;
	margin-left: -9px;
}

.attachment-opening-time p {
	padding: 10px 0;
	font-size: 12px;
}

/** NEW STYLE **/

.form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #cbd9e2;
}
.form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #cbd9e2;
}
.form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #cbd9e2;
}
.form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #cbd9e2;
}
.form-control::placeholder {
	color: #cbd9e2;
}

body {
	color: #333;
	background-color: #f4f7f9;
	font-family: 'Open Sans', sans-serif;
}

.main {
	max-width: 850px;
	width: 100%;
	margin: 20px auto 0;
	box-shadow: 0 0 8px 0 rgba(97, 97, 97, 0.75);
}

.align-right {
	text-align: right;
}

header {
	margin-top: 15px;
	margin-bottom: 15px;
}

.header-content .logo {
	float: left;
	width: 223px;
	height: 40px;
	image-rendering: -moz-crisp-edges; /* Firefox */
	image-rendering: -o-crisp-edges; /* Opera */
	image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
	image-rendering: crisp-edges;
	-ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

.tuv-logo {
	image-rendering: -moz-crisp-edges; /* Firefox */
	image-rendering: -o-crisp-edges; /* Opera */
	image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
	image-rendering: crisp-edges;
	-ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

.tel {
	margin-top: -3px;
}
.call {
	margin-bottom: 0;
}
.call .number {
	background-image: none;
	padding-left: 0;
}
.call .number:before {
	font-family: 'Glyphicons-Halflings', sans-serif;
	content: '\E182';
	color: #ff8d02;
	float: left;
	margin-right: 10px;
	font-size: 14px;
	margin-top: 4px;
}
.call .number:hover {
	text-decoration: none;
}
.call .number span {
	font-size: 18px;
	color: #005583;
	font-weight: 400;
	font-family: 'Rubik', sans-serif;
}
@media screen and (max-width: 550px) {
	.call .number span {
		display: none;
	}
}

h1 {
	font-family: 'Titillium Web', sans-serif;
	font-weight: 400;
	font-size: 28px;
	color: #006699;
	margin-bottom: 20px;
	margin-top: 0;
}

.sidebar h2 {
	font-family: 'Titillium Web', sans-serif;
	font-weight: 400;
	font-size: 24px;
	color: #006699;
	margin-top: 0;
	margin-bottom: 20px;
}
.sidebar .section-title {
	font-family: 'Rubik', sans-serif;
	font-weight: 300;
	font-size: 16px;
	color: #006699;
}
.sidebar .btn {
	width: 100%;
}
.sidebar .security-logos {
	margin-top: 30px;
}
.sidebar .not-insured {
	margin-top: 30px;
}

.cute-box {
	position: absolute;
	right: 45px;
	top: 30px;
	background-color: #f9fcff;
	padding: 20px;
	border: 1px solid #eef4f7;
	border-radius: 10px;
	color: #004473;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	font-size: 12px;
	width: 182px;
}
.cute-box div:first-of-type {
	margin-bottom: 10px;
}
@media screen and (max-width: 1200px) {
	.cute-box {
		position: initial;
		height: 40px;
		padding: 10px 20px;
		width: 100%;
	}
	.cute-box div {
		float: left;
		width: 50%;
	}
	.cute-box div:last-of-type:after {
		content: '';
		clear: both;
	}
}
@media screen and (max-width: 590px) {
	.cute-box {
		position: initial;
		height: auto;
		padding: 10px 20px;
		width: 100%;
	}
	.cute-box div {
		float: none;
		width: auto;
	}
}

.form-section {
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	font-weight: normal;
}
.form-group-gender {
	margin-bottom: 4px;
}
.form-section-title .title-help-container {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	color: #575757;
	background-color: #f9fcff;
	margin-bottom: 20px;
	border-top: 1px solid #0a65ad;
	border-bottom: 1px solid #0a65ad;
}
.form-section-title .title-help-container:before {
	content: '';
	/*background-image: url('/img/title-help-tick.png');*/
	width: 20px;
	height: 11px;
	float: right;
	margin-top: -11px;
}
.form-section-title .title-help-container .content {
	padding: 20px 20px 20px 20px;
}
.form-section-title .title-help-container .content h3 {
	margin-top: 0;
	font-size: 13px;
	font-weight: bold;
}
.form-section-title .title-help-container .help-close {
	float: none;
	position: relative;
	height: 30px;
	border-top: 1px solid #0a65ad;
	cursor: pointer;
}
.form-section-title .title-help-container .help-close:after {
	content: '\E014';
	font-family: 'Glyphicons-Halflings', sans-serif;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	display: inline-block;
	position: absolute;
	color: lightgray;
	font-size: 15px;
	top: 50%;
	transform: translate(-50%, -48%);
	left: 50%;
}
.form-section-signature {
	margin-top: 0;
}
.form-section:last-of-type {
	border: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}
.form-section h2 {
	font-family: 'Rubik', sans-serif;
	font-size: 18px;
	color: #006699;
	font-weight: normal;
	margin-top: 15px;
	margin-bottom: 15px;
}
.form-section .help-group {
	margin-left: -10px;
	margin-right: -10px;
}
.form-section-end {
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid #eeeeee;
}
/*.form-group-gender label[for=""]{
    display: none;
}*/
div.form-control {
	border: 0;
	box-shadow: none;
	padding: 0;
}
input.has-success:not(#client_survey_chargeDate) {
	background-image: none !important;
	box-shadow: none;
}
input.has-error {
}
input.form-control {
	background-color: #fcfcfc;
	border: 2px solid #e1e8ed;
	border-radius: 3px;
	color: #4f8aab;
	font-weight: bold;
	box-shadow: none;
	font-size: 16px;
	height: 40px;
}
.has-success .form-control {
	border-color: #e1e8ed;
	padding: 6px 36px 6px 12px;
}
.has-error .form-control {
	padding: 6px 36px 6px 12px;
}
.has-success .form-control:focus,
.form-control:focus {
	border-color: #ff8d02;
	box-shadow: none;
}
.has-success .form-control:focus {
	box-shadow: none; /*inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ff8d02;*/
}
.form-group-special.has-success {
	background-image: none !important;
	box-shadow: none !important;
	border: 0 !important;
}
.form-group-special.has-error {
	background-image: none !important;
	box-shadow: none !important;
	border: 0 !important;
}
.form-group-special.has-success:after {
	float: right;
	font-size: 20px;
	margin-top: -34px;
	margin-right: 12px;
	font-family: 'Glyphicons-Halflings', sans-serif;
	content: '\E084';
	color: rgb(46, 204, 113);
}
.form-group-special.has-error:after {
	/*    font-size: 20px;
        margin-top: -34px;
        margin-right: 12px;
        font-family: 'Glyphicons-Halflings';
        content: "\E084";
        color: #a94442;*/
}

.finish[disabled],
.finish.disabled {
	opacity: 0.4;
}

button.next:after {
	content: '\E080';
	font-family: 'Glyphicons-Halflings', sans-serif;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	display: inline-block;
	position: relative;
	color: white;
	font-size: 20px;
	top: 12px;
	transform: translateY(-45%);
	left: 10px;
}

button.back {
	border: 1px solid #e1e8ed;
	margin-right: 5px;
	color: #e1e8ed;
	font-size: 18px;
	padding: 11px 20px;
	font-weight: normal;
}

button.back:hover {
	background: #e1e8ed;
	border-color: #e1e8ed;
	color: #bdc4c9;
}

.help-icon {
	padding-top: 29px;
	padding-left: 30px;
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	color: #575757;
}

.auto-sig {
	cursor: pointer;
}
.personal-data .help-icon {
	padding-top: 68px;
}
.vollmacht .help-icon {
	padding-top: 34px;
}
.help-icon h3 {
	margin-top: 0;
	font-size: 13px;
	font-weight: bold;
}
.help-icon .help-content {
	margin-top: 3px;
	display: none;
}
.help-icon-visible .icon {
	background-position: 20px 0;
}
.help-icon-visible .help-content {
	display: block;
}
@media screen and (max-width: 1198px) {
	.vollmacht .help-icon {
		padding-top: 52px;
	}
}

.help-section .right {
	float: right;
}
@media screen and (max-width: 990px) {
	.help-section .right {
		float: none;
	}
}

.agb-box label {
	font-weight: normal;
}
.icheckbox_polaris {
	margin-top: -1px;
}

.signature-hint {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	color: #575757;
}

h2.signature {
	padding-right: 0;
}
div.sign-digital-links {
	margin-bottom: 0 !important;
}

.success-info-container,
.kosten-info-container {
	background-color: #f9fcff;
	border: 1px solid #eff5f8;
	border-radius: 10px;
	padding: 20px;
	font-size: 12px;
	color: #004473;
	display: flex;
	align-items: center;
}

.success-info-container a,
.kosten-info-container a {
	font-weight: bold;
	color: #004473;
}

.success-info-container .check-logo,
.kosten-info-container .check-logo {
	flex: 0 0 auto;
	width: 40px;
	margin-right: 15px;
}

.success-info-container .success,
.kosten-info-container .success {
	color: #1eb05c;
	font-weight: bold;
}

.success-info-container {
	padding: 10px 20px;
}

.success-info-container .success {
	vertical-align: baseline;
}

.success-info-container .check-logo {
	height: 37px;
}

@media (max-width: 1200px) {
	.success-info-container {
		margin-top: 15px;
	}
}

.not-insured {
	font-family: 'Open Sans', sans-serif;
	font-size: 12px;
	color: #575757;
	margin-top: 12px;
}

.not-insured .intro-text {
	padding-top: 5px;
}

.not-insured .table {
	margin-top: 20px;
}
.not-insured .table .total {
	margin-top: 0;
	font-size: 14px;
	font-family: 'Rubik', sans-serif;
	color: #006699;
	font-weight: normal;
	display: flex;
	padding: 0 15px;
	align-items: flex-end;
}

.not-insured .table .total .total-amount {
	margin-left: auto;
	flex: 0 0 120px;
	text-align: right;
	padding-left: 30px;
}

.not-insured .is-insured,
.help-section .is-insured {
	background-color: #f9fcff;
	border: 1px solid #eef4f7;
	border-radius: 10px;
	text-align: center;
	margin: 20px 0;
	padding: 10px 20px;
}
.not-insured .is-insured a,
.help-section .is-insured a {
	font-weight: bold;
	color: #1eb05c;
}
.not-insured .small_print {
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	color: #e3e3e3;
	width: 80%;
	margin: 20px auto;
}
.sidebar .not-insured .small_print {
	margin-bottom: 0;
}
.not-insured .small_print_bold {
	color: #575757;
}

.verguetung .not-insured .is-insured {
	margin-top: 0;
}

.form-submit-container {
	text-align: center;
	margin-top: 20px;
}

.form_title {
	font-size: 14px;
	color: #006699;
	margin: 0 0 15px 0;
}
.form-submit-container .btn-primary {
	background-color: #ff8d02;
	font-size: 20px;
	font-family: 'Titillium Web', sans-serif;
	padding: 10px 30px;
	font-weight: 400;
}
.form-submit-container .btn-primary:hover {
	background-color: #ffac02;
}
@media screen and (max-width: 1200px) {
	.sidebar .form-submit-container .btn-primary {
		font-size: 14px;
	}
}

.security-logos {
	margin-top: 30px;
	font-size: 12px;
	font-family: 'Open Sans', sans-serif;
	color: #004473;
	line-height: 12px;
}
.security-logos .tls {
	margin-bottom: 10px;
}

/* Signature override */
.auto-sig {
	padding-left: 10px !important;
	padding-right: 10px !important;
}
@media screen and (max-width: 500px) {
	span.handfont-x,
	.sigpad-x {
		font-size: 20px !important;
	}
}
div.signature-pad-digital {
	margin-bottom: 35px !important;
}
@media screen and (max-width: 462px) {
	div.signature-pad-digital {
		margin-bottom: 55px !important;
	}
}

/* polaris override */
.iradio_polaris {
	background-position: -155px 0;
}
/*@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_polaris,
    .iradio_polaris {
        background-image: url(/img/polaris@2x.png);
        -webkit-background-size: 310px 31px;
        background-size: 310px 31px;
    }
}*/

.sidebar-wrapper.affix {
	position: fixed !important;
	top: 20px;
	width: 100%;
}

.sidebar-wrapper.affix-top,
.sidebar-wrapper.affix-bottom {
	width: 100% !important;
	left: 0 !important;
	position: relative !important;
}

@media (max-width: 970px) {
	.first-col {
		width: 100% !important;
	}

	.second-col {
		display: none !important;
	}
}

.attachment-headline {
	font-weight: bold;
	margin-top: 15px;
}

.number-attachment:before {
	font-size: 20px !important;
	color: inherit !important;
	margin-top: 2px !important;
}

.attachment-footer {
	padding-top: 0 !important;
}

.fileinput-button input {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	opacity: 0;
	-ms-filter: 'alpha(opacity=0)';
	font-size: 200px !important;
	direction: ltr;
	cursor: pointer;
}

.subpage #error-body {
	padding: 2rem;
}

.cli-switch-container {
	margin: 0 auto;
	border-radius: 10px;
	padding: 20px;
	font-size: 12px;
	color: #004473;
	max-width: 293.4px;
	text-align: center;
}

.cli-switch-container > a {
	color: #ff8d02;
	font-weight: bold;
}
