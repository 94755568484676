$primary-colour: #006699;
$primary-colour-light: rgba(162, 186, 201, 0.3);
$secondary-colour: #ff8d02;
$success-colour: #2ecc71;
$error-colour: #cc2c28;
$white: #ffffff;
$light-grey: #f1f4f4;
$grey: #bcbebe;
$light-grey-blue: #f4f7f9;
$lighter-grey-blue: mix(white, $light-grey-blue, 40%);

$break-lg: 1200px;
$break-md: 992px;
$break-sm: 768px;
$break-xs: 375px;
$break-xxs: 320px;

//@import "../../node_modules/bootstrap/dist/css/bootstrap.css";

@import './mixins';
@import './app.css';

/** IMPORT PARTIALS */
@import './components/normalise';
@import './components/typography';
@import './components/layout';
@import './components/misc';
@import './components/select';
@import './components/text-input';
@import './components/radio-input';
@import './components/section';
@import './components/button';
@import './components/lds-ring';
@import './components/message';
@import './components/dropzone';
@import './components/modal';
