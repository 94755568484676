$col-padding: 1.5rem;
$col-numbers: 12;
$break-points: xxs, xs, sm, md, lg;

%padding {
  padding-left: $col-padding;
  padding-right: $col-padding;
  flex: 0 0 auto;
  box-sizing: border-box;
}


@mixin col-x-list {
  @each $break-point in $break-points {
    .col-#{$break-point} {
      @for $i from 1 through $col-numbers {
        &-#{$i} {
          @extend %padding;
        }
      }
    }
  }

}

@include col-x-list;

.container {
  max-width: $break-lg;
  @extend %padding;
  margin-right: auto;
  margin-left: auto;
}

@each $break-point in $break-points {
  .hidden-#{$break-point} {
    @include respondOnly($break-point) {
      display: none;
    }
  }
  .col-#{$break-point} {
    @for $i from 1 through $col-numbers {
      &-#{$i} {
        @include respondFrom($break-point) {
          width: ($i / $col-numbers * 100) * 1% !important;
        }
      }
    }
  }
}
