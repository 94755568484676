.warning {
  margin-left: auto;
  margin-right: auto;
  max-width: 768px;
  width: 100%;
  background-color: #FF7474;
  padding: 8px 42px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  color: #FFFFFF;
}