.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
  overflow: scroll;
  z-index: 1000;
  &--after-open{
    opacity: 1;
  }
  &--before-close{
    opacity: 0;
  }
}

.modal-dialog, .modal-dialog-narrow {
  max-width: 750px;
  width: 100%;
  background-color: $white;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  display: block;
  outline: none;
  margin: 0;
  position: relative;
  top: -3rem;
  transition: top 250ms;
  padding: 2rem;
  line-height: 1.6;
  overflow: hidden;
  &-header {
    font-size: 2.8rem;
    line-height: 3.5rem;
    font-family: "Titillium Web", sans-serif;
    font-weight: 400;
    color: $primary-colour;
    margin-top: 0;
    margin-bottom: 2rem !important;
  }
  &-footer {
    border-top: 1px solid $light-grey;
    padding-top: 2rem;
  }
  > div:not(:last-of-type){
    margin-bottom: 2rem;
  }
  &--after-open{
    top: 0;
  }
  &--before-close{
    top: -5rem;
  }
}

.modal-dialog-close {
  height: 2rem;
  position: absolute;
  width: 2rem;
  top: 1rem;
  right: 1rem;
  color: mix(white, $primary-colour, 70%);
  cursor: pointer;
  &:hover {
    color: $primary-colour;
  }
}

.modal-dialog-narrow {
  max-width: 500px;
}


body.modal-is-open {
  overflow: hidden;
}

.modal-dialog-content {
  & p:not(:last-of-type) {
    margin-bottom: 12px;
  }

  & ul {
    list-style: disc;
    padding-left: 30px;
    margin-bottom: 12px;
  }

  & cite {
    font-style: italic;
    margin-bottom: 12px;
  }
}

// CMP Vendors List on Data Privacy Modal

.cmpvendorlist {
  font-family: 'Open Sans',sans-serif;
  font-weight: 300;
  font-size: 10pt;
  color: #4b4b4b;

  ul li {
    font-size: 10pt;
  }

  a {
    color: $secondary-colour;
    text-decoration: none;
  }
}

.cmpvendorlistheadertext {
  font-size: 14px;
  font-weight: 400;
}

.cmpvendorlistheader {
  background: linear-gradient(180deg,rgba(255, 255, 255, 0),#eeeeee 100%) !important;

}

.cmpvendorlistheader:hover {
  background: linear-gradient(180deg,rgba(255, 255, 255, 0),#afafaf 100%) !important;
}

.cmpvendorinfohl {
  margin-bottom: 8px;
}

.cookieinfotable {
  background: #fff;
  margin-bottom: 0 !important;
  border: solid 1px #ddd;
  color: #4b4b4b;
}