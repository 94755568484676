.white-container {
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 2.5rem 3rem 3rem;
  min-height: 250px;
  position: relative;
  @include respondOnly(xxs, xs, sm, md) {
    padding: 1.5rem 2rem 2rem;
  }
  > div:first-of-type {
    margin-top: 0 !important;
  }
}

.inactive {
  color: $primary-colour-light !important;
}

svg {
  height: 100%;
}

.title-container {
  padding-bottom: 2.5rem;
  border-bottom: 1px solid $light-grey;
  color: $primary-colour;
  font-family: 'Open Sans', sans-serif;
  white-space: normal;
  .subtitle {
    font-size: 1.6rem;
    font-weight: 200;
    padding-top: 1.4rem;
    b {
      display: block;
      margin-bottom: 0.5rem;
      font-weight: 800;
    }
  }
}

.file-upload-title {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  &-icon {
    height: 1.75rem;
    color: $success-colour;
    padding-left: 0.4rem;
  }
}

.file-upload-container {
  border: 2px solid $light-grey;
  overflow: hidden;
  margin-top: 2.9rem;
}

.file-upload-header {
  background-color: $lighter-grey-blue;
  padding: 1.5rem 2rem;
  font-size: 1.4rem;
  font-weight: 300;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  border: 2px solid $light-grey;
  border-bottom: 0;
  ul {
    margin: 0;
    padding-left: 1.5rem;
    li {
      line-height: 1.6;
      list-style: disc;
      &:not(:last-of-type) {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.file-upload-footer-add-more {
  position: relative;
  color: $secondary-colour;
  font-weight: bold;
  padding: 4rem 2rem 0;
  width: 100%;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  display: block;
  &:after {
    content: '';
    position: absolute;
    top: 2rem;
    left: 1rem;
    width: calc(100% - 2rem);
    border-bottom: 2px solid $light-grey;
  }
  span {
    position: relative;
    &:before {
      content: '+';
      position: absolute;
      left: 0;
      transform: translateX(-150%);
      font-size: 2.1rem;
      font-weight: bold;
      top: -3px;
    }
  }
  &:hover {
    color: mix(black, $secondary-colour, 20%);
  }
}

.file-upload-content {
  padding: 2rem 2rem 0;
  border-left: 2px solid $light-grey;
  border-right: 2px solid $light-grey;
}

.text-center {
  text-align: center;
}

#main-column {
  flex: 1;
  padding-left: $col-padding;
  padding-right: $col-padding;
  @include respondOnly(xxs, xs, sm) {
    background-color: $white;
    padding: 3rem 4rem 4rem;

    .white-container {
      box-shadow: none !important;
      padding: 0 !important;
    }
  }
}

#sidebar-column {
  flex: 0 0 34rem;
  width: 34rem;
  padding-left: $col-padding;
  padding-right: $col-padding;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

.table {
  th {
    text-align: left;
    padding-right: 2rem;
  }
}

.success-footer {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: $grey;
  font-weight: 200;
  &-icon {
    color: $success-colour;
    display: flex;
    justify-content: center;
    svg {
      height: 5rem;
      width: auto;
    }
  }
  &-title {
    margin: 2rem 0;
    color: $success-colour;
    font-weight: bold;
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 20px;
    margin-top: 30px;
    background-color: #F8FAFB;
    border: 1px solid #EFF1F2
  }
  &__line {
    margin-top: 30px;
    width: 100%;
    background-color: #f1f4f4;
    border: none;
    display: block;
    height: 1px;
  }
  &__title {
    margin-bottom: 26px;
    background: #174466;
    border-radius: 100px;
    padding: 6px 20px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    text-transform: uppercase;
  }
  &__info {
    margin-bottom: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #006699;
  }
  &__description {
    margin-bottom: 25px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #006699;
    & > b  {
      font-weight: 400;
    }
  }
  &__sent {
    padding: 10px 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $success-colour;
  }
  &__submit {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & .select-input {
      background-color: #fff;
    }
    & .select-dropdown-item {
      text-align: left;
      color: #333333;
    }
  }
  &__submit-btn {
    margin-top: 10px;
    width: 100%;
    & button {
      width: 100%;
    }
    & span {
      white-space: nowrap;
    }
  }
}

@media screen and (min-width: 1024px) {
  .success-footer {
    &__box {
      padding: 30px;
      margin-top: 40px;
    }
    &__submit {
      flex-direction: row;
    }
    &__submit-btn {
      margin-top: 0;
      margin-left: 10px;
      width: initial;
    }
  }
}

.insurance-button-container {
  display: flex;
  justify-content: flex-start;
}
