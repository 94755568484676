.radio-input- {
  &container {
    position: relative;
    &.is-valid {

    }
    &.is-not-valid {

    }
  }
  &subline {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    font-weight: 300;
    display: block;
    line-height: 2.2rem;
  }
  &error {
    background-color: $error-colour;
    color: $white;
    font-size: 11px;
    top: 0;
    position: absolute;
    padding: 1rem;
    left: 0;
    transform: translateY(-45%);
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 10px;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $error-colour transparent transparent transparent;
    }
  }
  &checked, &unchecked, &hover {
    display: none;
    padding-right: 5px;
    color: $secondary-colour;
  }
  &title {
    margin-bottom: 12px;
    font-weight: bold;
  }
  &wrapper {
    display: flex;
  }
  &group {
    cursor: pointer;
    margin: 0;
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      padding-right: 10px;
      box-sizing: border-box;
    }
    &.is-checked .radio-input-checked {
      display: inline-flex;
    }
    &.is-not-checked {
      .radio-input-unchecked {
        display: inline-flex;
      }
      &:hover {
        .radio-input-unchecked {
          display: none;
        }
        .radio-input-hover {
          display: inline-flex;
        }
      }
    }
    & svg {
      width: 18px;
      height: 18px;
    }
  }
  &item {
    display: none;
  }
}