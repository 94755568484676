@mixin respondOnly($medias...) {
  @if (index($medias, xxs)) {
    @media only screen and (max-width: $break-xs - 1) {
      @content;
    }
  }
  @if (index($medias, xs)) {
    @media only screen and (min-width: $break-xs) and (max-width: $break-sm - 1) {
      @content;
    }
  }
  @if (index($medias, sm)) {
    @media only screen and (min-width: $break-sm) and (max-width: $break-md - 1) {
      @content;
    }
  }
  @if (index($medias, md)) {
    @media only screen and (min-width: $break-md) and (max-width: $break-lg -1) {
      @content;
    }
  }
  @if (index($medias, lg)) {
    @media only screen and (min-width: $break-lg) {
      @content;
    }
  }
}

@mixin respondFrom($medias...) {
  @if (index($medias, xxs)) {
    @media only screen and (min-width: $break-xxs) {
      @content;
    }
  }
  @if (index($medias, xs)) {
    @media only screen and (min-width: $break-xs) {
      @content;
    }
  }
  @if (index($medias, sm)) {
    @media only screen and (min-width: $break-sm) {
      @content;
    }
  }
  @if (index($medias, md)) {
    @media only screen and (min-width: $break-md) {
      @content;
    }
  }
  @if (index($medias, lg)) {
    @media only screen and (min-width: $break-lg) {
      @content;
    }
  }
}

@mixin respondNot ($medias...) {
  @if (not index($medias, xxs)) {
    @media only screen and (max-width: $break-xs - 1) {
      @content;
    }
  }
  @if (not index($medias, xs)) {
    @media only screen and (min-width: $break-xs) and (max-width: $break-sm - 1) {
      @content;
    }
  }
  @if (not index($medias, sm)) {
    @media only screen and (min-width: $break-sm) and (max-width: $break-md - 1) {
      @content;
    }
  }
  @if (not index($medias, md)) {
    @media only screen and (min-width: $break-md) and (max-width: $break-lg - 1) {
      @content;
    }
  }
  @if (not index($medias, lg)) {
    @media only screen and (min-width: $break-lg) {
      @content;
    }
  }
}

@mixin respondFromTo($from, $to) {
  @media only screen and (min-width: $from) and (max-width: $to) {
    @content
  }
}