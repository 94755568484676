.select- {
  &container {
    width: 100%;
    position: relative;
  }
  &container.is-valid {
    .select-input {
      border-color: $success-colour;
    }
  }
  &container.is-not-valid {
    .select-input {
      border-color: $error-colour;
    }
  }
  &icon-down {
    position: absolute;
    right: 1rem;
    top: 0.8rem;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) -10%,
      rgba(255, 255, 255, 0.542454481792717) 25%,
      rgba(255, 255, 255, 1) 50%
    );
    box-sizing: border-box;
    width: 5rem;
    display: flex;
    justify-content: flex-end;
    padding-top: 0.5rem;
    svg {
      height: 1.5rem;
      transition: 0.3s transform;
    }
    &.is-open {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  &dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: white;
    width: 100%;
    border-radius: 0 0 3px 3px;
    box-shadow: 0 14px 28px rgba(0, 52, 103, 0.08), 0 10px 10px rgba(0, 52, 103, 0.11);
    transition: 0.3s height ease-out, 0.3s opacity ease-out;
    overflow: hidden;
    z-index: 10;
    &-is-open {
      opacity: 1;
    }
    &-wrapper {
      position: relative;
    }
    &-error {
      background-color: $error-colour;
      color: $white;
      font-size: 11px;
      top: 0;
      position: absolute;
      padding: 1rem;
      right: 0;
      transform: translateY(-110%);
      &::after {
        content: '';
        position: absolute;
        top: 100%;
        right: 10px;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: $error-colour transparent transparent transparent;
      }
    }
    &-search {
      outline: 0;
      border-radius: 3px;
      padding: 1rem;
      border: 1px $primary-colour-light solid;
      width: 100%;
      box-sizing: border-box;
      font-weight: 300;
      &-container {
        position: relative;
        padding: 2rem 2rem 2rem;
        border-bottom: 2px $light-grey-blue solid;
      }
      &-icon {
        position: absolute;
        right: 3rem;
        top: 40%;
        color: $primary-colour;
        display: flex;
        svg {
          height: 1.5rem;
        }
      }
    }
    &-item {
      padding: 0.5rem 2rem;
      cursor: pointer;
      font-weight: 300;
      &:first-of-type {
        margin-top: 1.5rem;
      }
      &:last-of-type {
        margin-bottom: 1.5rem;
      }
      &:hover {
        background-color: lighten($primary-colour-light, 10%);
      }
      &-container {
        max-height: 20rem;
        overflow-y: scroll;
      }
      .text-input-container {
        margin: 0.5rem 0;
      }
    }
  }
  &input {
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    outline: 0;
    line-height: 1.7;
    border-radius: 0.2rem;
    border: 2px solid $primary-colour-light;
    padding: 0.7rem 1.2rem;
    cursor: default;
    font-weight: 300;
    box-sizing: border-box;
  }
  &label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}

.select-no-result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    color: $primary-colour-light;
    height: 8rem;
    margin: 2rem 0 2rem;
  }
  .select-no-result-message {
    padding: 1.5rem 2rem;
    text-align: center;
    color: $primary-colour;
    font-weight: 300;
  }
}
