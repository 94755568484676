.file-size {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.file-size__list {
    list-style: none !important;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

.file-size__list i {
    font-weight: 300;
    color: #006699;
}

.file-size__action {
    border-top: 1px solid #f1f4f4;
    margin-top: 20px;
    padding-top:  20px;
    display: flex;
    justify-content: flex-end;
}

.file-size__button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 9px 16px;
    width: 240px;
    height: 40px;
    background: #2ECC71;
    border: 1px solid #29B765;
    border-radius: 4px;
}

.file-size__button:hover {
    cursor: pointer;
}