.message {
  width: calc(100% - 4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
  padding: 2rem;
  margin: 0 2rem 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  .message-progress {
    animation: progress 5s linear;
    background-color: rgba(0, 0, 0, 0.3);
    height: 0.5rem;
    justify-self: flex-end;
    border-radius: 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &-container {
    position: fixed;
    top: 8rem;
    max-width: 75rem;
    width: 100%;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    box-sizing: border-box;
  }
  &-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: rgba(0, 0, 0, 0.5);
    &:hover {
      cursor: pointer;
      color: white;
    }
    svg {
      height: 2rem;
      width: 2rem;
    }
  }
  &-content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &.success {
    background-color: desaturate(lighten($success-colour, 5%), 10%);
    color: $white;
  }
  &.warning {
    background-color: desaturate(lighten($secondary-colour, 5%), 10%);
    color: $white;
  }
  &.danger {
    background-color: desaturate(lighten($error-colour, 5%), 10%);
    color: $white;
  }
  &.info {
    background-color: desaturate(lighten($primary-colour, 5%), 10%);
    color: $white;
  }
  &-enter {
    opacity: 0;
    &.message-enter-active {
      opacity: 1;
      transition: opacity 500ms ease-in;
    }
  }
  &-leave {
    opacity: 1;
    &.message-leave-active {
      opacity: 0;
      transition: opacity 300ms ease-in;
    }
  }
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
