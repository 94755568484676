@import url(./partials/style.css);

h2.signature {
	font-size: 53px;
	border-bottom: 1px solid #b0b0b0;
	display: inline-block;
	margin: 45px 0 15px 0;
	padding-right: 50px;
	line-height: 38px;
	font-weight: normal;
	white-space: nowrap;
}

span.handfont-x {
	font-family: Arial, sans-serif;
	font-weight: normal;
	font-size: 38px;
	color: #6b6b6b;
	margin-right: 15px;
}

h2.color-1 {
	color: #2467c1;
}

h2.color-2 {
	color: #1d169f;
}

h2.color-3 {
	color: #1f61af;
}

h2.color-4 {
	color: #0e2048;
}

h2.color-5 {
	color: #000000;
}

div.auto-sig {
	border: 1px solid #cacaca;
	border-radius: 5px;
	padding-left: 25px;
}

div.signature-pad-digital {
	display: block;
	margin-bottom: 55px;
}

div.signature-pad-manual {
	display: none;
}

.signature-pad-manual .signature-canvas canvas {
	width: 100%;
	height: 93px;
}

p.signature-ok {
	color: #777;
	font-size: 12px;
	margin-top: 5px;
	float: left;
}

p.signature-ok i {
	margin: 0 5px;
	color: #5cb85c;
}

a.sign-manually {
	margin-top: 7px;
	float: right;
	font-size: 11px;
}

a.sign-digitally {
	font-size: 11px;
	text-decoration: none !important;
	margin-bottom: 30px;
	margin-top: 10px;
}

div.sign-digital-links {
	float: right;
	margin-bottom: 20px;
}

div.sign-digital-links .btn {
	padding: 8px;
	background-color: #ffffff;
	border: 1px solid #247aa8;
	color: #247aa8;
	font-weight: normal;
}

div.sign-digital-links .btn:hover {
	background-color: #247aa8;
	color: #ffffff;
}

.loader-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	color: #247aa8;
	font-weight: bold;
	z-index: -1;
}

.loading .loader-container {
	z-index: 1;
}

.loading {
	overflow: hidden;
	height: 250px;
}

.sidebar-case-information {
	margin-top: 20px;
	color: #006699;
	background-color: #f4f7f9;
	padding: 20px;
}

.white-container {
	background-color: white;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
	padding: 30px;
	min-height: 250px;
	position: relative;
}

.data-overview-head {
	padding-top: 20px;
	display: flex;
	justify-content: space-between;
}
.data-overview-headline {
	font-family: 'Rubik';
	font-weight: 300;
	font-size: 16px;
	color: #006699;
}

.data-overview-body {
	padding-top: 10px;
	font-size: 12px;
	line-height: 1.2;
}
.data-overview-body span {
	display: block;
	padding: 2px 0;
}

.data-overview-head-button {
	color: #006699;
	cursor: pointer;
}
.data-overview-head-button span.link {
	padding-right: 9px;
	padding-top: 4px;
}

.white-container.sidebar.data-overview {
	display: none !important;
}
